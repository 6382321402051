<template>
  <div class="">
    <div class="column">
      <form>
        <div class="columns">
          <div class="column is-3">
            <b-field
              label="Auto de Infração:"
              class="mb-0"
              @change="$emit('change', recurso)"
            >
              <b-input
                v-model="recurso.ait"
                disabled
                @change="$v.recurso.ait.$touch()"
              />
            </b-field>
          </div>
          <div class="column is-4">
            <b-field label="Tipo de Solicitação:" class="mb-0">
              <b-select
                v-model="recurso.tipoRecursoSolicitacao"
                @change="$v.recurso.tipoRecursoSolicitacao.$touch()"
                placeholder="selecione"
                name="tipoRecursoSolicitacao"
              >
                <option
                  v-for="solicitacao in tiposSolicitacao"
                  :key="solicitacao.cod"
                  :value="solicitacao.cod"
                >
                  {{ solicitacao.desc }}
                </option>
              </b-select>
            </b-field>
            <div
              class="error mt-1 mb-1"
              v-if="
                !$v.recurso.tipoRecursoSolicitacao.required &&
                  $v.recurso.tipoRecursoSolicitacao.$dirty
              "
            >
              Campo requerido
            </div>
          </div>
        </div>
        <!--Campo de Nome-->
        <div class="block">
          <div class="columns">
            <div class="column is-6">
              <b-field
                label="Nome Completo"
                class="mb-0"
                @change="$emit('change', recurso)"
              >
                <b-input
                  v-model="recurso.nomeInteressado"
                  name="Nome"
                  @change="$v.recurso.nomeInteressado.$touch()"
                />
              </b-field>
              <div
                class="error mt-1 mb-1"
                v-if="
                  !$v.recurso.nomeInteressado.required &&
                    $v.recurso.nomeInteressado.$dirty
                "
              >
                Campo requerido
              </div>
            </div>

            <div class="column is-6">
              <b-field label="Endereço" class="mb-0">
                <b-input
                  v-model="recurso.enderecoLogradouro"
                  name="Logradouro"
                  @change="$v.recurso.enderecoLogradouro.$touch()"
                />
              </b-field>
              <div
                class="error mt-1 mb-1"
                v-if="
                  !$v.recurso.enderecoLogradouro.required &&
                    $v.recurso.enderecoLogradouro.$dirty
                "
              >
                Campo requerido
              </div>
            </div>
          </div>
          <!--Campo de Logradouro-->
          <div>
            <div class="columns mt-2">
              <!--Campo de Numero (Dentro da mesma div do logradouro)-->
              <div class="column is-2">
                <b-field
                  label="Número"
                  class="mb-0"
                  @change="$emit('change', recurso)"
                >
                  <b-input
                    v-model="recurso.enderecoNumero"
                    name="Numero"
                    @change="$v.recurso.enderecoNumero.$touch()"
                  />
                </b-field>
                <div
                  class="error mt-1 mb-1"
                  v-if="
                    !$v.recurso.enderecoNumero.required &&
                      $v.recurso.enderecoLogradouro.$dirty
                  "
                >
                  Campo requerido
                </div>
                <div
                  class="error mt-1 mb-1"
                  v-if="
                    !$v.recurso.enderecoNumero.numeric &&
                      $v.recurso.enderecoNumero.$dirty
                  "
                >
                  Apenas números
                </div>
              </div>
              <!--Campo Complemento (Dentro da mesma div de Endereco)-->
              <div class="column is-4">
                <b-field
                  label="Complemento"
                  class="mb-0"
                  @change="$emit('change', recurso)"
                >
                  <b-input
                    v-model="recurso.enderecoComplemento"
                    name="Complemento"
                    @change="$v.recurso.enderecoComplemento.$touch()"
                  />
                </b-field>
              </div>

              <div class="column is-2">
                <b-field
                  label="CEP"
                  class="mb-0"
                  @change="$emit('change', recurso)"
                >
                  <b-input
                    v-model="recurso.enderecoCep"
                    v-mask="'#####-###'"
                    name="CEP"
                    @change="$v.recurso.enderecoCep.$touch()"
                  />
                </b-field>
                <div
                  class="error mt-1 mb-1"
                  v-if="
                    !$v.recurso.enderecoCep.required &&
                      $v.recurso.enderecoCep.$dirty
                  "
                >
                  Campo requerido
                </div>
                <div
                  class="error mt-1 mb-1"
                  v-if="!$v.recurso.enderecoCep.minLength"
                >
                  O CEP está incompleto
                </div>
              </div>

              <!--Campo Bairro-->
              <div class="column is-4">
                <b-field
                  label="Bairro"
                  class="mb-0"
                  @change="$emit('change', recurso)"
                >
                  <b-input
                    v-model="recurso.enderecoBairro"
                    name="Bairro"
                    @change="$v.recurso.enderecoBairro.$touch()"
                  />
                </b-field>
                <div
                  class="error mt-1 mb-1"
                  v-if="
                    !$v.recurso.enderecoBairro.required &&
                      $v.recurso.enderecoBairro.$dirty
                  "
                >
                  Campo requerido
                </div>
              </div>
            </div>
            <!--Div do CEP (Vai até o UF)-->
            <div class="columns">
              <!--Município-->
              <div class="column is-4">
                <b-field
                  label="Município"
                  class="mb-0"
                  @change="$emit('change', recurso)"
                >
                  <b-input
                    v-model="recurso.enderecoMunicio"
                    name="Município"
                    @change="$v.recurso.enderecoMunicio.$touch()"
                  />
                </b-field>
                <div
                  class="error mt-1 mb-1"
                  v-if="
                    !$v.recurso.enderecoMunicio.required &&
                      $v.recurso.enderecoMunicio.$dirty
                  "
                >
                  Campo requerido
                </div>
              </div>
              <div class="column is-1">
                <b-field label="UF" class="mb-0">
                  <b-select
                    v-model="recurso.enderecoUf"
                    @change="$v.recurso.enderecoUf.$touch()"
                    placeholder="UF"
                    name="UFEndereco"
                  >
                    <option
                      v-for="estado in estados"
                      :key="estado"
                      :value="estado"
                    >
                      {{ estado }}
                    </option>
                  </b-select>
                </b-field>
                <div
                  class="error mt-1 mb-1"
                  v-if="
                    !$v.recurso.enderecoUf.required &&
                      $v.recurso.enderecoUf.$dirty
                  "
                >
                  Campo requerido
                </div>
              </div>
              <div class="column is-2">
                <b-field
                  label="Celular"
                  class="mb-0"
                  @change="$emit('change', recurso)"
                >
                  <b-input
                    v-model="recurso.celular"
                    v-mask="'(##) #####-####'"
                    name="celular"
                    @change="$v.recurso.celular.$touch()"
                  />
                </b-field>
                <div
                  class="error mt-1 mb-1" v-if="!$v.recurso.celular.minLength">
                  Número inválido
                </div>
              </div>
              <div class="column is-4">
                <b-field
                  label="E-mail"
                  class="mb-0"
                  @change="$emit('change', recurso)"
                >
                  <b-input
                    v-model="recurso.email"
                    name="Município"
                    @change="$v.recurso.email.$touch()"
                  />
                </b-field>
                <div
                  class="error mt-1 mb-1"
                  v-if="!$v.recurso.email.email && $v.recurso.email.$dirty"
                >
                  E-mail inválido
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {
  required,
  integer,
  minLength,
  numeric,
  maxLength,
  email
} from 'vuelidate/lib/validators'
import Cleave from 'cleave.js'

const cleave = {
  name: 'cleave',
  bind (el, binding) {
    const input = el.querySelector('input')
    input._vCleave = new Cleave(input, binding.value)
  },
  unbind (el) {
    const input = el.querySelector('input')
    input._vCleave.destroy()
  }
}

export default {
  name: 'StepCadastroDados',
  props: {
    recurso: {
      type: Object,
      required: true
    }
  },
  directives: { cleave },
  data () {
    return {
      estados: [
        'AC',
        'AL',
        'AP',
        'AM',
        'BA',
        'CE',
        'DF',
        'ES',
        'GO',
        'MA',
        'MT',
        'MS',
        'MG',
        'PA',
        'PB',
        'PR',
        'PE',
        'PI',
        'RJ',
        'RN',
        'RS',
        'RO',
        'RR',
        'SC',
        'SP',
        'SE',
        'TO'
      ],
      tiposSolicitacao: [
        { cod: 'P', desc: 'DEFESA DE AUTUAÇÃO' },
        { cod: 'A', desc: 'ADVERTÊNCIA' },
        { cod: 'J', desc: 'JARI' },
        { cod: 'C', desc: 'CETRAN' }
      ]
    }
  },
  validations: {
    recurso: {
      enderecoNumero: { required, integer, numeric },
      enderecoCep: {
        required,
        minLength: minLength(9),
        maxLength: maxLength(9)
      },
      tipoRecursoSolicitacao: { required },
      nomeInteressado: { required },
      enderecoLogradouro: { required },
      enderecoBairro: { required },
      enderecoMunicio: { required },
      enderecoUf: { required },
      celular: { minLength: minLength(15), maxlength: maxLength(15) },
      email: {
        email
      }
    }
  },
  async beforeMount () {
    this.recurso.ehProprietario = false
  },
  methods: {
    validate () {
      return new Promise(resolve => {
        if (!this.$v.$invalid) {
          resolve(true)
        } else {
          this.$v.$touch()
          resolve(false)
        }
      })
    },
    mostrarEndereco (e) {
      if (this.recurso.ehProprietario) {
        this.limparEndereco()
      }
    },
    limparEndereco () {
      this.recurso.nomeInteressado = ''
      this.recurso.logradouro = ''
      this.recurso.numero = ''
      this.recurso.complemento = ''
      this.recurso.cep = ''
      this.recurso.bairro = ''
      this.recurso.municipio = ''
      this.recurso.uf = ''
    }
  }
}
</script>
<style>
.error {
  color: #d11313;
  font-size: 12px;
}
</style>
